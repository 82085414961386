.work-msg {
  font-weight: 300;
  font-size: 12px;

  color: $brown;

  display: block;
  // margin-top: 5px;
  margin-left: 15px;

  @media (min-width: $breakpoint-tablet) {
    display: inline;
    margin-left: 10px;
  }
}

.work-pagination {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1;
  cursor: pointer;
}

.work-img-wrapper {
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;

  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 20px;

  max-width: unset;

  img {
    height: 100%;
    max-width: unset;
    display: block;
  }

  // counter-increment: work-counter;

  .caption {
    padding-top: 0.4em;
    color: $brown;
    font-size: 15px !important;
  }

  .caption p {
    font-size: 15px !important;
  }

  // .caption > *:first-child {
  //   &:before {
  //     content: counter(work-counter) ". ";
  //     display: inline-block;
  //   }
  // }
}

.work-wrapper {
  position: relative;
}

.work-image-list {
  display: flex;

  overflow-x: scroll;
  padding-bottom: 10px;

  counter-reset: work-counter;

  // border: 1px solid green;

  @media (min-width: $breakpoint-tablet) {
    padding-left: 50%;
  }
}
