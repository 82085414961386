.journal-post {
  // column-width: 18em;
  // column-width: 200px;

  /* height: 400px; */
  // height: 70vh;
  // overflow-x: scroll;

  min-height: 60vh;

  font-size: 15px;

  .journal-image {
    // max-height: 80vh;
  }

  .copy {
    font-size: 15px;
    color: $brown;

    p {
      margin-bottom: 1em;
      color: $brown;
    }

    @media (min-width: $breakpoint-tablet) {
      width: 75%;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    padding-left: 50%;
  }

  .journal-image {
    margin: 0 0 16px 0;

    img {
      display: block;
    }
  }
}

.journal-date {
  font-weight: 300;
  font-size: 13px;
  // margin-left: 5px;
}

.journal-wrapper {
  position: relative;

  // height: 60vh;
}
