.home-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  padding: 6vw;
  padding-top: 0;

  @media (min-width: $breakpoint-tablet) {
    padding: 3vw;
    // Make space for nav
    left: 200px;
    // Override section offset
    top: 0 !important;
  }
}

.home-image {
  width: 100%;
  height: 100%;

  // Make the image element act like a background image
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (min-width: $breakpoint-tablet) {
      object-position: right;
    }
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes outAnimation {
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.foo {
  position: absolute;
  border: 1px solid green;
}
