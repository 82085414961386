@font-face {
  font-family: 'Untitled sans';
  src: url('/assets/fonts/untitled-sans-web-regular.woff2') format('woff2'),
    url('/assets/fonts/untitled-sans-web-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Untitled sans';
  src: url('/assets/fonts/untitled-sans-web-light.woff2') format('woff2'),
    url('/assets/fonts/untitled-sans-web-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

$muted: #747474;
$brown: #986a4e;

$breakpoint-tablet: 768px;

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

html {
  height: 100%;
}

body {
  font-family: 'Untitled sans', sans-serif;
  font-weight: 300;
  background: #fff;
  color: #111;
  height: 100%;
  padding: 6vw 6vw;
  -webkit-text-size-adjust: none;

  @media (min-width: $breakpoint-tablet) {
    padding: 3vw;
  }
}

.root {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.main {
  flex: 1;
}

ul {
  list-style: none;
  margin-bottom: 1em;
}

a {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1;
  font-size: 20px;
}

.site-heading {
  margin: 0;
  margin-bottom: 20px;
  color: $muted;

  > a {
    text-decoration: none;
  }
}

.sections {
  position: relative;
}

.section-heading {
  margin: 0;
  margin-bottom: 0rem;

  cursor: pointer;
  display: flex;
  align-items: center;

  text-decoration: none;
  color: #646464;

  transition: color 0.6s ease;
  &--active {
    color: $brown;
  }
}

.about,
.contact {
  font-size: 15px;

  p {
    margin-bottom: 1em;
    color: $brown;
  }

  // @TODO: selector for rich text headings
  h3 {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0.8em;
  }
}

.section-nav {
  @media (min-width: $breakpoint-tablet) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  font-size: 17px;

  li {
    cursor: pointer;
    margin-bottom: 0.3em;

    &:before {
      content: '– ';
    }
  }
}

.section-nav__item {
  color: #7f807f;

  &--active {
    color: #000;
    // color: #0000ee;
  }
}

.section {
  margin-bottom: 5px;

  &__content {
    overflow: hidden;
    transition: opacity 0.4s ease;
    padding-top: 1rem;
    padding-bottom: 15px;
  }
}

.accordion__icon {
  margin-left: 3px;
  width: 15px;
  height: 15px;

  svg {
    transition: transform 0.6s ease;

    width: 100%;
    height: 100%;
  }

  &--rotate {
    svg {
      transform: rotate(45deg);
    }
  }
}

footer {
  margin-top: 10px;
}

.copyright {
  color: $brown;
  font-weight: 300;
  font-size: 10px;
}

@import './sections/home';
@import './sections/about';
@import './sections/works';
@import './sections/journal';
@import './sections/contact';
